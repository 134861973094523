<template>
  <v-sheet :color="userStatus.color" :dark="userStatus.dark">
    <v-row justify="center">
      <v-col cols="12" md="6" v-if="loading">
        <area-loading />
      </v-col>
      <v-col cols="11" md="8" v-if="!loading">
        <v-row class="my-2 justify-center">
          <v-icon class="mb-2" size="30">mdi-card-account-details</v-icon>
          <span class="ml-4 text-h5 font-weight-bold">{{
            userStatus.text
          }}</span>
        </v-row>
        <hr />
        <div class="mb-4" align="center">
          <h2 class="my-6">{{ program.title }}</h2>
          <h4>{{ $t('activerecord.attributes.venue.title') }}</h4>
          {{ program.venue.title }}
          <hr />
          <h1 class="mt-4">{{ `${profile.name} 様` }}</h1>
        </div>
        <div class="word-breaker" align="center">
          {{ $t('description.certificate') }}
        </div>
        <button-to-answer-form
          :answer-form-url="program.answer_form_url"
          color="success"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import UserApi from '@/api/users'
import AreaLoading from '@/components/areas/common/Loading'
import ButtonToAnswerForm from '@/components/atoms/btn/ToAnswerForm'
export default {
  mixins: [UserApi],
  components: {
    AreaLoading,
    ButtonToAnswerForm,
  },
  props: {
    program: { type: Object, required: true },
  },
  data: () => ({
    loading: false,
    profile: {
      name: '',
      user_type: '',
    },
  }),
  created() {
    this.getProfile()
  },
  computed: {
    userStatus() {
      switch (this.profile.user_type) {
        case 'regular':
          return {
            color: '#008FD3',
            text: this.$t('enum.user_type.regular'),
            dark: true,
          }
        case 'special':
          return {
            color: '#FFF176',
            text: this.$t('enum.user_type.special'),
            dark: false,
          }
        case 'general':
          return {
            color: '#69F0AE',
            text: this.$t('enum.user_type.general'),
            dark: false,
          }
        case 'student':
          return {
            color: '#FFCDD2',
            text: this.$t('enum.user_type.student'),
            dark: false,
          }
        case 'graduate':
          return {
            color: '#E53935',
            text: this.$t('enum.user_type.graduate'),
            dark: true,
          }
        default:
          return { color: '', text: '' }
      }
    },
  },
  methods: {
    getProfile() {
      this.axios.get(this.API.users.show).then((res) => {
        this.profile = res.data.user.profile
      })
    },
  },
  metaInfo() {
    return this.$t(`meta_tags.${this.$route.name}`)
  },
}
</script>
